export const namespaced = true

export const state = () => ({})

export const getters = {}

export const actions = {
	downloadDocumentUser({ dispatch }, { documentID, userID }) {
		return this.$api
			.get(`/documents/user/${userID}/document/${documentID}`, {
				responseType: 'blob'
			})
			.then(({ headers, data }) => {
				try {
					const filename = headers['content-disposition'].split('filename=')[1]
					const type = headers['content-type']
					const blob = new Blob([data], {
						type,
						encoding: 'UTF-8'
					})
					const link = document.createElement('a')
					link.href = window.URL.createObjectURL(blob)
					link.download = filename
					link.click()
				} catch {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	downloadDocumentApplication({ dispatch }, { parentID }) {
		return this.$api
			.get(`/documents/application/${parentID}`, {
				responseType: 'blob'
			})
			.then(({ headers, data }) => {
				try {
					const filename = headers['content-disposition'].split('filename=')[1]
					const type = headers['content-type']
					const blob = new Blob([data], {
						type,
						encoding: 'UTF-8'
					})
					const link = document.createElement('a')
					link.href = window.URL.createObjectURL(blob)
					link.download = filename
					link.click()
				} catch {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	downloadDocumentAttendance({ dispatch }, { parentID }) {
		return this.$api
			.get(`/documents/attendance/${parentID}`, {
				responseType: 'blob'
			})
			.then(({ headers, data }) => {
				try {
					const filename = headers['content-disposition'].split('filename=')[1]
					const type = headers['content-type']
					const blob = new Blob([data], {
						type,
						encoding: 'UTF-8'
					})
					const link = document.createElement('a')
					link.href = window.URL.createObjectURL(blob)
					link.download = filename
					link.click()
				} catch {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	noDocument({ dispatch }) {
		dispatch('snackbar/push', { success: false, message: this.$i18n.t('documents.noDocument') }, { root: true })
	}
}

export const mutations = {}
