import Vue from 'vue'
import Vuex from 'vuex'

import * as applications from './modules/applications.js'
import * as attendances from './modules/attendances.js'
import * as auth from './modules/auth.js'
import * as companies from './modules/companies.js'
import * as cv from './modules/cv.js'
import * as data from './modules/data.js'
import * as documents from './modules/documents.js'
import * as events from './modules/events.js'
import * as feed from './modules/feed.js'
import * as offers from './modules/offers.js'
import * as sidebar from './modules/sidebar.js'
import * as notifications from './modules/notifications.js'
import * as dialog from './modules/dialog.js'
import * as popup from './modules/popup.js'
import * as snackbar from './modules/snackbar.js'
import * as settings from './modules/settings.js'
import * as chats from './modules/chats.js'
import * as splash from './modules/splash.js'
import * as invoices from './modules/invoices.js'
import * as subscriptions from './modules/subscriptions.js'
import * as users from './modules/users.js'
import * as user from './modules/user.js'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {},
	mutations: {},
	actions: {},
	modules: {
		applications,
		attendances,
		auth,
		companies,
		cv,
		data,
		documents,
		events,
		feed,
		offers,
		notifications,
		sidebar,
		dialog,
		popup,
		snackbar,
		settings,
		chats,
		splash,
		invoices,
		subscriptions,
		users,
		user
	},
	plugins: []
})
