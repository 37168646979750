export const namespaced = true

export const state = () => ({
	skills: [],
	availableSkills: [],
	projects: [],
	languages: [],
	availableLanguages: [],
	sectors: [],
	availableSectors: []
})

export const getters = {
	skills: (state) => state.skills,
	availableSkills: (state) => state.availableSkills,
	projects: (state) => state.projects,
	languages: (state) => state.languages,
	availableLanguages: (state) => state.availableLanguages,
	sectors: (state) => state.sectors,
	availableSectors: (state) => state.availableSectors
}

export const actions = {
	fetchAvailableSkills({ commit, dispatch }, { keywords }) {
		return this.$api
			.get('/cv/skills/data', {
				params: {
					locale: this.$i18n.locale,
					keywords
				}
			})
			.then(({ data }) => {
				if (data.success) {
					commit('UPDATE_AVAILABLE_SKILLS', data.skills)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	fetchSkills({ commit, dispatch }, { userID }) {
		commit('SET_SKILLS', [])
		return this.$api
			.get(`/cv/skills/user/${userID}`)
			.then(({ data }) => {
				if (data.success) {
					commit('SET_SKILLS', data.skills)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	fetchProjects({ commit, dispatch }, { userID }) {
		commit('SET_PROJECTS', [])
		return this.$api
			.get(`/cv/projects/user/${userID}`)
			.then(({ data }) => {
				if (data.success) {
					commit('SET_PROJECTS', data.projects)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	fetchAvailableLanguages({ commit, dispatch }, { keywords }) {
		return this.$api
			.get('/cv/languages/data', {
				params: {
					locale: this.$i18n.locale,
					keywords
				}
			})
			.then(({ data }) => {
				if (data.success) {
					commit('UPDATE_AVAILABLE_LANGUAGES', data.languages)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	fetchLanguages({ commit, dispatch }, { userID }) {
		commit('SET_LANGUAGES', [])
		return this.$api
			.get(`/cv/languages/user/${userID}`)
			.then(({ data }) => {
				if (data.success) {
					commit('SET_LANGUAGES', data.languages)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	fetchAvailableSectors({ commit, dispatch }, { keywords }) {
		return this.$api
			.get('/cv/sectors/data', {
				params: {
					locale: this.$i18n.locale,
					keywords
				}
			})
			.then(({ data }) => {
				if (data.success) {
					commit('UPDATE_AVAILABLE_SECTORS', data.sectors)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	fetchSectors({ commit, dispatch }, { userID }) {
		commit('SET_SECTORS', [])
		return this.$api
			.get(`/cv/sectors/user/${userID}`)
			.then(({ data }) => {
				if (data.success) {
					commit('SET_SECTORS', data.sectors)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	}
}

export const mutations = {
	SET_SKILLS: (state, skills) => (state.skills = skills),
	SET_AVAILABLE_SKILLS: (state, availableSkills) => (state.availableSkills = availableSkills),
	UPDATE_AVAILABLE_SKILLS: (state, availableSkills) => (state.availableSkills = Array.from(new Set(state.availableSkills.concat(availableSkills)))),
	SET_PROJECTS: (state, projects) => (state.projects = projects),
	SET_LANGUAGES: (state, languages) => (state.languages = languages),
	SET_AVAILABLE_LANGUAGES: (state, availableLanguages) => (state.availableLanguages = availableLanguages),
	UPDATE_AVAILABLE_LANGUAGES: (state, availableLanguages) =>
		(state.availableLanguages = Array.from(new Set(state.availableLanguages.concat(availableLanguages)))),
	SET_SECTORS: (state, sectors) => (state.sectors = sectors),
	SET_AVAILABLE_SECTORS: (state, availableSectors) => (state.availableSectors = availableSectors),
	UPDATE_AVAILABLE_SECTORS: (state, availableSectors) =>
		(state.availableSectors = Array.from(new Set(state.availableSectors.concat(availableSectors)))),
	ADD_SECTOR: (state, sector) => state.sectors.push(sector)
}
