<template>
	<v-app>
		<Splash />
		<Sidebar v-if="$vuetify.breakpoint.mdAndDown" />
		<Navbar />
		<v-main app>
			<router-view />

			<Dialog />
			<SnackBar />
			<PopUp />
		</v-main>
		<Footer v-if="!$vuetify.breakpoint.mdAndDown" />
		<BottomNavbar v-if="$vuetify.breakpoint.mdAndDown" />
	</v-app>
</template>

<script>
export default {
	components: {
		Sidebar: () => import('@/components/main/Sidebar.vue'),
		Navbar: () => import('@/components/main/Navbar.vue'),
		Footer: () => import('@/components/main/Footer.vue'),
		BottomNavbar: () => import('@/components/main/BottomNavbar.vue'),
		PopUp: () => import('@/components/main/PopUp.vue'),
		Dialog: () => import('@/components/feedback/Dialog.vue'),
		SnackBar: () => import('@/components/feedback/SnackBar.vue'),
		Splash: () => import('@/components/feedback/Splash.vue')
	}
}
</script>
