export const namespaced = true

export const state = () => ({
	opened: true
})

export const getters = {
	opened: (state) => state.opened
}

export const actions = {
	open({ commit }) {
		commit('CHANGE_OPENED', true)
	},
	close({ commit }) {
		commit('CHANGE_OPENED', false)
	},
	toogle({ commit }, opened) {
		commit('CHANGE_OPENED', opened)
	}
}

export const mutations = {
	CHANGE_OPENED: (state, opened) => (state.opened = opened)
}
