export const namespaced = true

const companyDefault = {
	imgURL: ''
}

export const state = () => ({
	company: companyDefault,
	availableSectors: []
})

export const getters = {
	company: (state) => state.company,
	availableSectors: (state) => state.availableSectors
}

export const actions = {
	fetchCompany({ commit, dispatch }) {
		commit('REMOVE_COMPANY')
		return this.$api
			.get('/companies')
			.then(({ data }) => {
				if (data.success) {
					commit('SET_COMPANY', data.company)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	register({ commit, dispatch }, { name, description, website, employees, sectors, coupon }) {
		return this.$api
			.put('/companies/register', {
				name,
				description,
				website,
				employees,
				sectors,
				coupon
			})
			.then(({ data }) => {
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				if (data.success) {
					commit('SET_COMPANY', data.company)
					commit('user/SET_SETUP_INFO', data.setupInfo, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	joinCompany({ commit, dispatch }, token) {
		return this.$api
			.post('/companies/join', {
				token
			})
			.then(({ data }) => {
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				if (data.success) {
					this.$api.defaults.headers.common.Authorization = 'Bearer ' + data.accessToken
					this.$i18n.locale = data.currentUser.locale.key
					this.$moment.locale(data.currentUser.locale.key)
					commit('auth/AUTH_SUCCESS', data.accessToken, { root: true })
					commit('user/SET_CURRENT_USER', data.currentUser, { root: true })
					commit('user/SET_SETUP_INFO', data.setupInfo, { root: true })
					dispatch('user/fetchStyleTheme', null, { root: true })
					dispatch('notifications/fetchNotifications', null, { root: true })
					if (data.setupInfo.joinedCompany) {
						dispatch('companies/fetchCompany', null, { root: true })
					}
					if (data.setupInfo.verified && !data.setupInfo.popupDoNotShow && data.setupInfo.popupShow) {
						dispatch('popup/push', data.setupInfo.popupMessage, { root: true })
					}
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	inviteAdmin({ commit, dispatch }, { email }) {
		return this.$api
			.post('/companies/invite', {
				email
			})
			.then(({ data }) => {
				if (data.success) {
					commit('companies/INVITE_ADMIN', data.invitedAdmin, { root: true })
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	cancelInvitation({ commit, dispatch }, { employerID }) {
		return this.$api
			.post(`/companies/invite/${employerID}/cancel`)
			.then(({ data }) => {
				if (data.success) {
					commit('companies/REMOVE_ADMIN', employerID, { root: true })
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	removeAdmin({ commit, dispatch }, { employerID }) {
		return this.$api
			.post(`/companies/remove/${employerID}`)
			.then(({ data }) => {
				if (data.success) {
					commit('companies/REMOVE_ADMIN', employerID, { root: true })
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	changeContact({ commit, dispatch }, { employer }) {
		return this.$api
			.post('/companies/settings/contact', {
				employerID: employer.id
			})
			.then(({ data }) => {
				if (data.success) {
					commit('companies/CHANGE_CONTACT', employer, { root: true })
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	updateCompanyLogo({ commit, dispatch }, { formData }) {
		return this.$api
			.post('/companies/settings/logo', formData)
			.then(({ data }) => {
				if (data.success) {
					commit('companies/UPDATE_IMG_URL', data.imgURL, { root: true })
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	deleteCompanyLogo({ commit, dispatch }) {
		return this.$api
			.delete('/companies/settings/logo')
			.then(({ data }) => {
				if (data.success) {
					commit('companies/UPDATE_IMG_URL', data.imgURL, { root: true })
				}
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	updateCompanyInfo({ commit, dispatch }, { name, website, employees, instagram, facebook, twitter, linkedin, youtube }) {
		return this.$api
			.post('/companies/settings/info', {
				name,
				website,
				employees,
				instagram,
				facebook,
				twitter,
				linkedin,
				youtube
			})
			.then(({ data }) => {
				if (data.success) {
					commit('companies/SET_COMPANY', data.company, { root: true })
				}
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	updateCompanyDescription({ commit, dispatch }, { description }) {
		return this.$api
			.post('/companies/settings/description', {
				description
			})
			.then(({ data }) => {
				if (data.success) {
					commit('companies/SET_COMPANY', data.company, { root: true })
				}
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	updateCompanyInvoiceInfo({ commit, dispatch }, { attention, companyName, email, address1, address2, postCode, city, province, country, taxCode }) {
		return this.$api
			.post(`/companies/settings/invoice`, {
				attention,
				companyName,
				email,
				address1,
				address2,
				postCode,
				city,
				province,
				country,
				taxCode
			})
			.then(({ data }) => {
				if (data.success) {
					commit('SET_COMPANY', data.company)
				}
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	redeemCoupon({ commit, dispatch }, { coupon }) {
		return this.$api
			.post('/companies/redeem', {
				coupon
			})
			.then(({ data }) => {
				if (data.success) {
					commit('SET_COMPANY', data.company)
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	fetchAvailableSectors({ commit, dispatch }, { keywords }) {
		return this.$api
			.get('/companies/sectors/data', {
				params: {
					locale: this.$i18n.locale,
					keywords
				}
			})
			.then(({ data }) => {
				if (data.success) {
					commit('UPDATE_AVAILABLE_SECTORS', data.sectors)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	addSector({ commit, dispatch }, { sectorID }) {
		return this.$api
			.put(`/companies/sectors`, {
				sectorID
			})
			.then(({ data }) => {
				if (data.success) {
					commit('ADD_SECTOR', data.sector)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	removeSector({ commit, dispatch }, { sectorID }) {
		return this.$api
			.delete(`/companies/sectors/${sectorID}`)
			.then(({ data }) => {
				if (data.success) {
					commit('REMOVE_SECTOR', sectorID)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	}
}

export const mutations = {
	SET_COMPANY: (state, company) => (state.company = company),
	REMOVE_COMPANY: (state) => (state.company = companyDefault),
	INVITE_ADMIN: (state, admin) => state.company.administrators.administrators.push(admin),
	REMOVE_ADMIN: (state, employerID) =>
		(state.company.administrators.administrators = state.company.administrators.administrators.filter((admin) => admin.id != employerID)),
	CHANGE_CONTACT: (state, contact) => (state.company.contact = contact),
	UPDATE_IMG_URL: (state, imgURL) => (state.company.imgURL = imgURL),
	SET_AVAILABLE_SECTORS: (state, availableSectors) => (state.availableSectors = availableSectors),
	UPDATE_AVAILABLE_SECTORS: (state, availableSectors) =>
		(state.availableSectors = Array.from(new Set(state.availableSectors.concat(availableSectors)))),
	ADD_SECTOR: (state, sector) => state.company.info.sectors.push(sector),
	EDIT_SECTOR: (state, sector) => (state.company.info.sectors[state.company.info.sectors.findIndex((x) => x.id === sector.id)] = sector),
	REMOVE_SECTOR: (state, sectorID) => (state.company.info.sectors = state.company.info.sectors.filter((sector) => sector.id !== sectorID))
}
