<template>
	<v-app>
		<LandingHeader />

		<v-main>
			<LandingCookies />

			<LandingHome />

			<LandingAbout />

			<LandingFeatures />

			<LandingPricing />

			<LandingContact />

			<LandingFooter />
		</v-main>
	</v-app>
</template>

<script>
export default {
	metaInfo() {
		return {
			title: this.$t('app.enterprise.name'),
			titleTemplate: null,
			meta: [
				{
					name: 'description',
					content:
						'OverSOS es la plataforma que conecta al estudiante directamente con el mundo laboral. ¡Encontrar talento nunca había sido tan fácil!'
				}
			]
		}
	},
	components: {
		LandingHeader: () => import('@/components/landing/LandingHeader.vue'),
		LandingCookies: () => import('@/components/landing/LandingCookies.vue'),
		LandingHome: () => import('@/components/landing/LandingHome.vue'),
		LandingAbout: () => import('@/components/landing/LandingAbout.vue'),
		LandingFeatures: () => import('@/components/landing/LandingFeatures.vue'),
		LandingPricing: () => import('@/components/landing/LandingPricing.vue'),
		LandingContact: () => import('@/components/landing/LandingContact.vue'),
		LandingFooter: () => import('@/components/landing/LandingFooter.vue')
	}
}
</script>
