export const namespaced = true

export const state = () => ({
	attendance: null,
	attendances: []
})

export const getters = {
	attendance: (state) => state.attendance,
	attendances: (state) => state.attendances
}

export const actions = {
	fetchAttendances({ commit, dispatch }, eventID) {
		commit('SET_ATTENDANCES', [])
		return this.$api
			.get(`/attendances/event/${eventID}`)
			.then(({ data }) => {
				if (data.success) {
					commit('SET_ATTENDANCES', data.attendances)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	prioritizeAttendance({ commit, dispatch }, { attendance, actionType }) {
		return this.$api
			.post(`/attendances/${attendance.id}/prioritize`, {
				actionType
			})
			.then(({ data }) => {
				if (data.success) {
					commit('SET_ATTENDANCE_STATUS', { attendance, status: data.status })
					commit('events/SET_EVENT_ATTENDANCES', data.event, { root: true })
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	discardAttendance({ commit, dispatch }, { attendance, actionType }) {
		return this.$api
			.post(`/attendances/${attendance.id}/discard`, {
				actionType
			})
			.then(({ data }) => {
				if (data.success) {
					commit('SET_ATTENDANCE_STATUS', { attendance, status: data.status })
					commit('events/SET_EVENT_ATTENDANCES', data.event, { root: true })
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	}
}

export const mutations = {
	SET_ATTENDANCE: (state, attendance) => (state.attendance = attendance),
	SET_ATTENDANCES: (state, attendances) => (state.attendances = attendances),
	SET_ATTENDANCE_STATUS: (state, { attendance, status }) => (state.attendances[state.attendances.indexOf(attendance)].status = status)
}
