export const namespaced = true

export const state = () => ({
	accessToken: localStorage.getItem('accessToken') || null
})

export const getters = {
	authenticated: (state) => !!state.accessToken,
	accessToken: (state) => state.accessToken
}

export const actions = {
	refreshAccess({ commit, dispatch }) {
		return this.$api
			.post('/auth/refresh')
			.then(({ data }) => {
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				if (data.success) {
					this.$api.defaults.headers.common.Authorization = 'Bearer ' + data.accessToken
					this.$i18n.locale = data.currentUser.locale.key
					this.$moment.locale(data.currentUser.locale.key)
					commit('auth/AUTH_SUCCESS', data.accessToken, { root: true })
					commit('user/SET_CURRENT_USER', data.currentUser, { root: true })
					commit('user/SET_SETUP_INFO', data.setupInfo, { root: true })
					dispatch('user/fetchStyleTheme', null, { root: true })
					dispatch('notifications/fetchNotifications', null, { root: true })
					if (data.setupInfo.joinedCompany) {
						dispatch('companies/fetchCompany', null, { root: true })
					}
					if (data.setupInfo.verified && !data.setupInfo.popup.doNotShow && data.setupInfo.popup.show) {
						dispatch('popup/push', { title: data.setupInfo.popup.title, message: data.setupInfo.popup.message }, { root: true })
					}
				} else {
					commit('auth/AUTH_LOGOUT', null, { root: true })
					commit('user/REMOVE_CURRENT_USER', null, { root: true })
					commit('user/REMOVE_SETUP_INFO', null, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				commit('auth/AUTH_LOGOUT', null, { root: true })
				commit('user/REMOVE_CURRENT_USER', null, { root: true })
				commit('user/REMOVE_SETUP_INFO', null, { root: true })
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	register({ commit, dispatch }, { email, name, locale, password, passwordAgain, promotionalEmails }) {
		return this.$api
			.post('/auth/register', {
				email,
				name,
				locale,
				password,
				passwordAgain,
				promotionalEmails
			})
			.then(({ data }) => {
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				if (data.success) {
					this.$api.defaults.headers.common.Authorization = 'Bearer ' + data.accessToken
					this.$i18n.locale = data.currentUser.locale.key
					this.$moment.locale(data.currentUser.locale.key)
					commit('auth/AUTH_SUCCESS', data.accessToken, { root: true })
					commit('user/SET_CURRENT_USER', data.currentUser, { root: true })
					commit('user/SET_SETUP_INFO', data.setupInfo, { root: true })
					dispatch('user/fetchStyleTheme', null, { root: true })
				} else {
					commit('auth/AUTH_LOGOUT', null, { root: true })
					commit('user/REMOVE_CURRENT_USER', null, { root: true })
					commit('user/REMOVE_SETUP_INFO', null, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	login({ commit, dispatch }, { email, password, rememberMe }) {
		return this.$api
			.post('/auth/login', {
				email,
				password,
				rememberMe
			})
			.then(({ data }) => {
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				if (data.success) {
					this.$api.defaults.headers.common.Authorization = 'Bearer ' + data.accessToken
					this.$i18n.locale = data.currentUser.locale.key
					this.$moment.locale(data.currentUser.locale.key)
					commit('auth/AUTH_SUCCESS', data.accessToken, { root: true })
					commit('user/SET_CURRENT_USER', data.currentUser, { root: true })
					commit('user/SET_SETUP_INFO', data.setupInfo, { root: true })
					dispatch('user/fetchStyleTheme', null, { root: true })
					dispatch('notifications/fetchNotifications', null, { root: true })
					if (data.setupInfo.joinedCompany) {
						dispatch('companies/fetchCompany', null, { root: true })
					}
					if (data.setupInfo.verified && !data.setupInfo.popup.doNotShow && data.setupInfo.popup.show) {
						dispatch('popup/push', { title: data.setupInfo.popup.title, message: data.setupInfo.popup.message }, { root: true })
					}
				} else {
					commit('auth/AUTH_LOGOUT', null, { root: true })
					commit('user/REMOVE_CURRENT_USER', null, { root: true })
					commit('user/REMOVE_SETUP_INFO', null, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
				// Remove user
				commit('auth/AUTH_LOGOUT', null, { root: true })
				commit('user/REMOVE_CURRENT_USER', null, { root: true })
				commit('user/REMOVE_SETUP_INFO', null, { root: true })
				commit('user/SET_STYLE_THEME', 'light', { root: true })
			})
	},
	logout({ commit, dispatch }) {
		return this.$api
			.post('/auth/logout')
			.then(({ data }) => {
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				// Remove user
				commit('auth/AUTH_LOGOUT', null, { root: true })
				commit('user/REMOVE_CURRENT_USER', null, { root: true })
				commit('user/REMOVE_SETUP_INFO', null, { root: true })
				commit('user/SET_STYLE_THEME', 'light', { root: true })
				// Remove everything else
				commit('applications/SET_APPLICATION', null, { root: true })
				commit('applications/SET_APPLICATIONS', [], { root: true })
				commit('attendances/SET_ATTENDANCE', null, { root: true })
				commit('attendances/SET_ATTENDANCES', [], { root: true })
				commit('chats/SET_CHAT', null, { root: true })
				commit('chats/SET_CHATS', [], { root: true })
				commit('companies/REMOVE_COMPANY', null, { root: true })
				commit('companies/SET_AVAILABLE_SECTORS', [], { root: true })
				commit('cv/SET_SKILLS', [], { root: true })
				commit('cv/SET_PROJECTS', [], { root: true })
				commit('cv/SET_LANGUAGES', [], { root: true })
				commit('cv/SET_SECTORS', [], { root: true })
				commit('cv/SET_AVAILABLE_SKILLS', [], { root: true })
				commit('cv/SET_AVAILABLE_LANGUAGES', [], { root: true })
				commit('cv/SET_AVAILABLE_SECTORS', [], { root: true })
				commit('events/SET_LOADING', false, { root: true })
				commit('events/SET_EVENT', null, { root: true })
				commit('events/SET_EVENTS', [], { root: true })
				commit('events/SET_EVENT_TYPES', [], { root: true })
				commit('feed/SET_NOTICES', [], { root: true })
				commit('notifications/SET_NOTIFICATIONS', [], { root: true })
				commit('offers/SET_LOADING', false, { root: true })
				commit('offers/SET_OFFER', null, { root: true })
				commit('offers/SET_OFFERS', [], { root: true })
				commit('subscriptions/SET_SUBSCRIPTION', null, { root: true })
				commit('subscriptions/SET_SUBSCRIPTIONS', [], { root: true })
				commit('subscriptions/SET_SUBSCRIPTION_TYPES', [], { root: true })
				commit('users/SET_USER', null, { root: true })
				commit('users/SET_TALENT_POOL', [], { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
				// Remove user
				commit('auth/AUTH_LOGOUT', null, { root: true })
				commit('user/REMOVE_CURRENT_USER', null, { root: true })
				commit('user/REMOVE_SETUP_INFO', null, { root: true })
				commit('user/SET_STYLE_THEME', 'light', { root: true })
				// Remove everything else
				commit('applications/SET_APPLICATION', null, { root: true })
				commit('applications/SET_APPLICATIONS', [], { root: true })
				commit('attendances/SET_ATTENDANCE', null, { root: true })
				commit('attendances/SET_ATTENDANCES', [], { root: true })
				commit('chats/SET_CHAT', null, { root: true })
				commit('chats/SET_CHATS', [], { root: true })
				commit('companies/REMOVE_COMPANY', null, { root: true })
				commit('companies/SET_AVAILABLE_SECTORS', [], { root: true })
				commit('cv/SET_SKILLS', [], { root: true })
				commit('cv/SET_PROJECTS', [], { root: true })
				commit('cv/SET_LANGUAGES', [], { root: true })
				commit('cv/SET_SECTORS', [], { root: true })
				commit('cv/SET_AVAILABLE_SKILLS', [], { root: true })
				commit('cv/SET_AVAILABLE_LANGUAGES', [], { root: true })
				commit('cv/SET_AVAILABLE_SECTORS', [], { root: true })
				commit('events/SET_LOADING', false, { root: true })
				commit('events/SET_EVENT', null, { root: true })
				commit('events/SET_EVENTS', [], { root: true })
				commit('events/SET_EVENT_TYPES', [], { root: true })
				commit('feed/SET_NOTICES', [], { root: true })
				commit('notifications/SET_NOTIFICATIONS', [], { root: true })
				commit('offers/SET_LOADING', false, { root: true })
				commit('offers/SET_OFFER', null, { root: true })
				commit('offers/SET_OFFERS', [], { root: true })
				commit('subscriptions/SET_SUBSCRIPTION', null, { root: true })
				commit('subscriptions/SET_SUBSCRIPTIONS', [], { root: true })
				commit('subscriptions/SET_SUBSCRIPTION_TYPES', [], { root: true })
				commit('users/SET_USER', null, { root: true })
				commit('users/SET_TALENT_POOL', [], { root: true })
			})
	},
	sendVerifyAccountEmail({ dispatch }) {
		return this.$api
			.post('/auth/verify/send')
			.then(({ data }) => {
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	verifyAccount({ commit, dispatch }, token) {
		return this.$api
			.post('/auth/verify/confirm', {
				token
			})
			.then(({ data }) => {
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				if (data.success) {
					this.$api.defaults.headers.common.Authorization = 'Bearer ' + data.accessToken
					this.$i18n.locale = data.currentUser.locale.key
					this.$moment.locale(data.currentUser.locale.key)
					commit('auth/AUTH_SUCCESS', data.accessToken, { root: true })
					commit('user/SET_CURRENT_USER', data.currentUser, { root: true })
					commit('user/SET_SETUP_INFO', data.setupInfo, { root: true })
					dispatch('user/fetchStyleTheme', null, { root: true })
					dispatch('notifications/fetchNotifications', null, { root: true })
					if (data.setupInfo.joinedCompany) {
						dispatch('companies/fetchCompany', null, { root: true })
					}
					if (data.setupInfo.verified && !data.setupInfo.popup.doNotShow && data.setupInfo.popup.show) {
						dispatch('popup/push', { title: data.setupInfo.popup.title, message: data.setupInfo.popup.message }, { root: true })
					}
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	sendResetPasswordEmail({ dispatch }, email) {
		return this.$api
			.post('/auth/resetPassword/send', {
				email
			})
			.then(({ data }) => {
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	resetPassword({ dispatch }, { token, password, passwordAgain }) {
		return this.$api
			.post('/auth/resetPassword', {
				token,
				password,
				passwordAgain
			})
			.then(({ data }) => {
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	}
}

export const mutations = {
	AUTH_SUCCESS: (state, accessToken) => {
		state.accessToken = accessToken
		localStorage.setItem('accessToken', accessToken)
	},
	AUTH_LOGOUT: (state) => {
		state.accessToken = null
		localStorage.removeItem('accessToken')
	}
}
