import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import colors from 'vuetify/es5/util/colors'

Vue.use(Vuetify)

export default new Vuetify({
	theme: {
		options: {
			customProperties: true
		},
		themes: {
			light: {
				background: '#EDF1FF',
				primary: colors.blue.darken1,
				secondary: colors.amber.darken3,
				accent: '#A63535',
				info: colors.teal.lighten1,
				success: colors.green.accent3,
				error: colors.deepOrange.accent4,
				warning: colors.amber.base,
				cardTitleBackground: '#BABB96',
				sidebarBackground: colors.blue.darken1
			},
			dark: {
				background: '#393939',
				primary: colors.blue.darken3,
				secondary: colors.amber.darken3,
				accent: '#A63535',
				info: colors.teal.lighten1,
				success: colors.green.accent3,
				error: colors.deepOrange.accent4,
				warning: colors.amber.base,
				cardTitleBackground: '#330000',
				sidebarBackground: colors.blue.darken4
			}
		}
	},
	icons: {
		iconfont: 'mdi'
	}
})
