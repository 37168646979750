<template>
	<v-container fluid style="max-width: 1200px">
		<h2 class="text-center">{{ type.toUpperCase() }} Error</h2>
		<p class="text-h5 text-center mt-10">
			<template v-if="type == 'network'">
				{{ $t('errors.messageNetwork') }}
			</template>
			<template v-else-if="type == '401'">
				{{ $t('errors.message401') }}
			</template>
			<template v-else-if="type == '403'">
				{{ $t('errors.message403') }}
			</template>
			<template v-else-if="type == '404'">
				{{ $t('errors.message404') }}
			</template>
			<template v-else-if="type == '410'">
				{{ $t('errors.message410') }}
			</template>
			<template v-else-if="type == '413'">
				{{ $t('errors.message413') }}
			</template>
			<template v-else-if="type == '500'">
				{{ $t('errors.message500') }}
			</template>
		</p>
		<br />
		<template v-if="type == '404'">
			<v-img class="ma-auto" contain :max-width="imageWidth" :max-height="imageHeight" :src="require(`@/assets/images/error/${type}.png`)" />
		</template>
		<template v-else>
			<h1 class="text-center text-h1">¯\_(ツ)_/¯</h1>
		</template>
	</v-container>
</template>

<script>
export default {
	metaInfo() {
		return {
			title: 'Error ' + this.type
		}
	},
	props: {
		type: {
			type: String,
			required: true
		}
	},
	computed: {
		imageWidth() {
			return window.innerWidth * 0.8
		},
		imageHeight() {
			return window.innerHeight * 0.8
		}
	}
}
</script>
