export const namespaced = true

export const state = () => ({
	user: null,
	talentPool: []
})

export const getters = {
	user: (state) => state.user,
	talentPool: (state) => state.talentPool
}

export const actions = {
	fetchUser({ commit, dispatch }, userID) {
		commit('SET_USER', null)
		return this.$api
			.get(`/users/${userID}`)
			.then(({ data }) => {
				if (data.success) {
					commit('SET_USER', data.user)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	fetchTalentPool({ commit, dispatch }, { offerID, page, itemsPerPage, search, skills, languages, sectors, degrees, cv, records, motivation }) {
		commit('SET_TALENT_POOL', [])
		return this.$api
			.get(`/users/pool`, {
				params: {
					offerID,
					page,
					itemsPerPage,
					search,
					skills,
					languages,
					sectors,
					degrees,
					cv,
					records,
					motivation
				}
			})
			.then(({ data }) => {
				if (data.success) {
					commit('SET_TALENT_POOL', data.users)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	includeUserInOffer({ dispatch }, { userID, offerID }) {
		return this.$api
			.post(`/users/${userID}/includeInOffer/${offerID}`)
			.then(({ data }) => {
				if (data.success) {
					dispatch('applications/fetchApplications', offerID, { root: true })
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	}
}

export const mutations = {
	SET_USER: (state, user) => (state.user = user),
	SET_TALENT_POOL: (state, talentPool) => (state.talentPool = talentPool)
}
