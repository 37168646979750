export const namespaced = true

export const state = () => ({
	availableLanguages: [],
	availableSemesters: [],
	availableOfferTypes: [],
	availableThesisOptions: [],
	availableCountries: [],
	availableEmployeeOptions: [],
	availableSpainProvinces: [],
	availableDegrees: []
})

export const getters = {
	availableLanguages: (state) => state.availableLanguages,
	availableSemesters: (state) => state.availableSemesters,
	availableOfferTypes: (state) => state.availableOfferTypes,
	availableThesisOptions: (state) => state.availableThesisOptions,
	availableCountries: (state) => state.availableCountries,
	availableEmployeeOptions: (state) => state.availableEmployeeOptions,
	availableSpainProvinces: (state) => state.availableSpainProvinces,
	availableDegrees: (state) => state.availableDegrees
}

export const actions = {
	fetchAvailableLanguages({ commit, dispatch }) {
		return this.$api
			.get('/data/languages/web', {
				params: {
					locale: this.$i18n.locale
				}
			})
			.then(({ data }) => {
				if (data.success) {
					commit('SET_AVAILABLE_LANGUAGES', data.languages)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	fetchSemesters({ commit, dispatch }) {
		return this.$api
			.get('/data/semesters', {
				params: {
					locale: this.$i18n.locale
				}
			})
			.then(({ data }) => {
				if (data.success) {
					commit('SET_AVAILABLE_SEMESTERS', data.semesters)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	fetchAvailableOfferTypes({ commit, dispatch }) {
		return this.$api
			.get('/data/offerTypes', {
				params: {
					locale: this.$i18n.locale
				}
			})
			.then(({ data }) => {
				if (data.success) {
					commit('SET_AVAILABLE_OFFER_TYPES', data.offerTypes)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	fetchAvailableThesisOptions({ commit, dispatch }) {
		return this.$api
			.get('/data/thesisOptions', {
				params: {
					locale: this.$i18n.locale
				}
			})
			.then(({ data }) => {
				if (data.success) {
					commit('SET_AVAILABLE_THESIS_OPTIONS', data.thesisOptions)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	fetchAvailableCountries({ commit, dispatch }) {
		return this.$api
			.get('/data/countries', {
				params: {
					locale: this.$i18n.locale
				}
			})
			.then(({ data }) => {
				if (data.success) {
					commit('SET_AVAILABLE_COUNTRIES', data.countries)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	fetchAvailableEmployeeOptions({ commit, dispatch }) {
		return this.$api
			.get('/data/employeeOptions', {
				params: {
					locale: this.$i18n.locale
				}
			})
			.then(({ data }) => {
				if (data.success) {
					commit('SET_AVAILABLE_EMPLOYEE_OPTIONS', data.employeeOptions)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	fetchAvailableSpainProvinces({ commit, dispatch }) {
		return this.$api
			.get('/data/provinces', {
				params: {
					locale: this.$i18n.locale
				}
			})
			.then(({ data }) => {
				if (data.success) {
					commit('SET_AVAILABLE_SPAIN_PROVINCES', data.provinces)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	fetchAvailableDegrees({ commit, dispatch }, { keywords }) {
		return this.$api
			.get('/data/degrees', {
				params: {
					locale: this.$i18n.locale,
					keywords
				}
			})
			.then(({ data }) => {
				if (data.success) {
					commit('UPDATE_AVAILABLE_DEGREES', data.degrees)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	contactSend({ dispatch }, { email, name, message }) {
		return this.$api
			.post('/data/contact', {
				params: {
					locale: this.$i18n.locale
				},
				email,
				name,
				message
			})
			.then(({ data }) => {
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	}
}

export const mutations = {
	SET_AVAILABLE_LANGUAGES: (state, availableLanguages) => (state.availableLanguages = availableLanguages),
	SET_AVAILABLE_SEMESTERS: (state, availableSemesters) => (state.availableSemesters = availableSemesters),
	SET_AVAILABLE_OFFER_TYPES: (state, availableOfferTypes) => (state.availableOfferTypes = availableOfferTypes),
	SET_AVAILABLE_THESIS_OPTIONS: (state, availableThesisOptions) => (state.availableThesisOptions = availableThesisOptions),
	SET_AVAILABLE_COUNTRIES: (state, availableCountries) => (state.availableCountries = availableCountries),
	SET_AVAILABLE_EMPLOYEE_OPTIONS: (state, availableEmployeeOptions) => (state.availableEmployeeOptions = availableEmployeeOptions),
	SET_AVAILABLE_SPAIN_PROVINCES: (state, availableSpainProvinces) => (state.availableSpainProvinces = availableSpainProvinces),
	UPDATE_AVAILABLE_DEGREES: (state, availableDegrees) =>
		(state.availableDegrees = Array.from(new Set(state.availableDegrees.concat(availableDegrees))))
}
