export const namespaced = true

export const state = () => ({
	notices: []
})

export const getters = {
	notices: (state) => state.notices
}

export const actions = {
	fetchNoticesOffer({ commit, dispatch }, { offerID }) {
		commit('SET_NOTICES', [])
		return this.$api
			.get(`/feed/notices/offer/${offerID}`)
			.then(({ data }) => {
				if (data.success) {
					commit('SET_NOTICES', data.notices)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	fetchNoticesEvent({ commit, dispatch }, { eventID }) {
		commit('SET_NOTICES', [])
		return this.$api
			.get(`/feed/notices/event/${eventID}`)
			.then(({ data }) => {
				if (data.success) {
					commit('SET_NOTICES', data.notices)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	publishNotice({ commit, dispatch }, { parent, parentID, title, content }) {
		return this.$api
			.put('/feed/notice', {
				parent,
				parentID,
				title,
				content
			})
			.then(({ data }) => {
				if (data.success) {
					commit('NEW_NOTICE', data.notice)
				}
				dispatch('dialog/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('dialog/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	editNotice({ dispatch }, { notice, title, content }) {
		return this.$api
			.post(`/feed/notice/${notice.id}`, {
				title: title,
				content: content
			})
			.then(({ data }) => {
				dispatch('dialog/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('dialog/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	deleteNotice({ commit, dispatch }, notice) {
		return this.$api
			.delete(`/feed/notice/${notice.id}`)
			.then(({ data }) => {
				if (data.success) {
					commit('REMOVE_NOTICE', notice.id)
				}
				dispatch('dialog/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('dialog/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	}
}

export const mutations = {
	SET_NOTICES: (state, notices) => (state.notices = notices),
	NEW_NOTICE: (state, notice) => state.notices.unshift(notice),
	REMOVE_NOTICE: (state, id) => (state.notices = state.notices.filter((notice) => notice.id !== id))
}
