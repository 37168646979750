export const namespaced = true

export const state = () => ({})

export const getters = {}

export const actions = {
	editAccountName({ commit, dispatch }, name) {
		return this.$api
			.post('/settings/account/name', {
				name
			})
			.then(({ data }) => {
				if (data.success) {
					commit('user/SET_CURRENT_USER', data.currentUser, { root: true })
				}
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	editAccountContact({ commit, dispatch }, { position, phone, email }) {
		return this.$api
			.post('/settings/account/contact', {
				position,
				phone,
				email
			})
			.then(({ data }) => {
				if (data.success) {
					commit('user/SET_CURRENT_USER', data.currentUser, { root: true })
				}
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	switchAccountTheme({ commit, dispatch }, darkTheme) {
		return this.$api
			.post('/settings/account/darktheme', {
				darkTheme
			})
			.then(({ data }) => {
				this.$vuetify.framework.theme.dark = data.dark
				if (data.dark) {
					commit('user/SET_STYLE_THEME', 'dark', { root: true })
				} else {
					commit('user/SET_STYLE_THEME', 'light', { root: true })
				}
				if (!data.success) {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	changeLocale({ commit, dispatch }, locale) {
		return this.$api
			.post('/settings/account/locale', {
				locale: locale
			})
			.then(({ data }) => {
				if (data.success) {
					this.$i18n.locale = locale.key
					this.$moment.locale(locale.key)
					commit('user/UPDATE_LOCALE', locale, { root: true })
					dispatch('user/fetchAvailableLanguages', null, { root: true })
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	changeEmail({ commit, dispatch }, email) {
		return this.$api
			.post('/settings/account/email', {
				email
			})
			.then(({ data }) => {
				if (data.success) {
					commit('user/SET_CURRENT_USER', data.currentUser, { root: true })
				}
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	changePassword({ dispatch }, { currentPassword, newPassword, newPasswordAgain }) {
		return this.$api
			.post('/settings/account/password/change', {
				currentPassword,
				newPassword,
				newPasswordAgain
			})
			.then(({ data }) => {
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	changeNotifications({ commit, dispatch }, emails) {
		return this.$api
			.post('/settings/notifications', {
				promotional: emails.promotional,
				newsletter: emails.newsletter,
				changesApproved: emails.changesApproved,
				changesRefused: emails.changesRefused
			})
			.then(({ data }) => {
				if (data.success) {
					commit('user/SET_CURRENT_USER', data.currentUser, { root: true })
				}
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	}
}

export const mutations = {}
