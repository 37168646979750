export const namespaced = true

export const state = () => ({
	loading: false,
	offer: null,
	offers: []
})

export const getters = {
	loading: (state) => state.loading,
	offer: (state) => state.offer,
	offers: (state) => state.offers
}

export const actions = {
	setLoadingOffer({ commit }, loading) {
		commit('SET_LOADING', loading)
	},
	fetchOffer({ commit, dispatch }, offerID) {
		commit('SET_OFFER', null)
		return this.$api
			.get(`/offers/${offerID}`)
			.then(({ data }) => {
				if (data.success) {
					commit('SET_OFFER', data.offer)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	fetchOffers({ commit, dispatch }) {
		commit('SET_OFFERS', [])
		return this.$api
			.get('/offers')
			.then(({ data }) => {
				if (data.success) {
					commit('SET_OFFERS', data.offers)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	createOffer({ commit, dispatch }, offer) {
		return this.$api
			.put('/offers/create', {
				category: offer.category,
				externalLink: offer.externalLink,
				title: offer.title,
				companyName: offer.companyName,
				city: offer.city,
				province: offer.province,
				country: offer.country,
				description: offer.description,
				qualifications: offer.qualifications,
				benefits: offer.benefits,
				startingDate: offer.startingDate,
				duration: offer.duration,
				salary: offer.salary,
				offerType: offer.offerType,
				fulltime: offer.fulltime,
				thesisOptions: offer.thesisOptions,
				sectors: offer.sectors
			})
			.then(({ data }) => {
				if (data.success) {
					commit('CREATE_OFFER', data.offer)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	editOffer({ commit, dispatch }, offer) {
		return this.$api
			.post(`/offers/${offer.id}/edit`, {
				externalLink: offer.externalLink,
				title: offer.title,
				city: offer.city,
				province: offer.province,
				country: offer.country,
				description: offer.description,
				qualifications: offer.qualifications,
				benefits: offer.benefits,
				startingDate: offer.startingDate,
				duration: offer.duration,
				salary: offer.salary,
				offerType: offer.offerType,
				fulltime: offer.fulltime,
				thesisOptions: offer.thesisOptions,
				sectors: offer.sectors
			})
			.then(({ data }) => {
				if (data.success) {
					commit('SET_OFFER', data.offer)
				}
				dispatch('dialog/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('dialog/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	updateOfferLogo({ commit, dispatch }, { offerID, formData }) {
		return this.$api
			.post(`/offers/${offerID}/logo`, formData)
			.then(({ data }) => {
				if (data.success) {
					commit('offers/UPDATE_IMG_URL', data.imgURL, { root: true })
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	deleteOfferLogo({ commit, dispatch }, offerID) {
		return this.$api
			.delete(`/offers/${offerID}/logo`)
			.then(({ data }) => {
				if (data.success) {
					commit('offers/UPDATE_IMG_URL', data.imgURL, { root: true })
				}
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	cancelChange({ commit, dispatch }, offersChangeID) {
		return this.$api
			.post(`/offers/${offersChangeID}/cancel`)
			.then(({ data }) => {
				if (data.success) {
					commit('SET_OFFER', data.offer)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	solveOffer({ commit, dispatch }, offerID) {
		return this.$api
			.post(`/offers/${offerID}/solve`)
			.then(({ data }) => {
				if (data.success) {
					commit('SET_OFFER', data.offer)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	closeOffer({ commit, dispatch }, offerID) {
		return this.$api
			.post(`/offers/${offerID}/close`)
			.then(({ data }) => {
				if (data.success) {
					commit('SET_OFFER', data.offer)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	openOffer({ commit, dispatch }, offerID) {
		return this.$api
			.post(`/offers/${offerID}/open`)
			.then(({ data }) => {
				if (data.success) {
					commit('SET_OFFER', data.offer)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	}
}

export const mutations = {
	SET_LOADING: (state, loading) => (state.loading = loading),
	SET_OFFER: (state, offer) => (state.offer = offer),
	SET_OFFERS: (state, offers) => (state.offers = offers),
	CREATE_OFFER: (state, offer) => {
		state.offer = offer
		state.offers.unshift(offer)
	},
	UPDATE_IMG_URL: (state, imgURL) => (state.offer.imgURL = imgURL)
}
