import Vue from 'vue'
import VueRouter from 'vue-router'

import Layout from '@/views/Layout'

import Landing from '@/views/Landing'

const AuthLogin = () => import('@/views/AuthLogin')
const AuthRegister = () => import('@/views/AuthRegister')
const AuthLogout = () => import('@/views/AuthLogout')
const AuthVerify = () => import('@/views/AuthVerify')
const AuthSetup = () => import('@/views/AuthSetup')
const AuthResetPassword = () => import('@/views/AuthResetPassword')

const Company = () => import('@/views/Company')
const CompanyInvitation = () => import('@/views/CompanyInvitation')

const Offer = () => import('@/views/Offer')
const OfferAbout = () => import('@/views/OfferAbout')
const OfferBenefits = () => import('@/views/OfferBenefits')
const OfferNeeds = () => import('@/views/OfferNeeds')
const OfferActivity = () => import('@/views/OfferActivity')
const OfferApplications = () => import('@/views/OfferApplications')
const OfferTalentPool = () => import('@/views/OfferTalentPool')

const Offers = () => import('@/views/Offers')
const OfferCreate = () => import('@/views/OfferCreate')

const Event = () => import('@/views/Event')
const EventFeed = () => import('@/views/EventFeed')
const EventDescription = () => import('@/views/EventDescription')
const EventActivity = () => import('@/views/EventActivity')
const EventAttendances = () => import('@/views/EventAttendances')

const Events = () => import('@/views/Events')
const EventCreate = () => import('@/views/EventCreate')

const Subscription = () => import('@/views/Subscription')

const Settings = () => import('@/views/Settings')
const SettingsAccount = () => import('@/views/SettingsAccount')
const SettingsCompany = () => import('@/views/SettingsCompany')
const SettingsNotifications = () => import('@/views/SettingsNotifications')
const SettingsSubscriptions = () => import('@/views/SettingsSubscriptions')
const SettingsInvoiceVerify = () => import('@/views/SettingsInvoiceVerify')

const Policies = () => import('@/views/Policies')
const PoliciesCookies = () => import('@/views/PoliciesCookies')
const PoliciesPrivacy = () => import('@/views/PoliciesPrivacy')
const PoliciesTerms = () => import('@/views/PoliciesTerms')

const Contact = () => import('@/views/Contact')
const FAQ = () => import('@/views/FAQ')
const Team = () => import('@/views/Team')
const Pricing = () => import('@/views/Pricing')
const PricingConfirm = () => import('@/views/PricingConfirm')

const Messages = () => import('@/views/Messages')
const MessagesChat = () => import('@/views/MessagesChat')
const MessagesChatOfferNew = () => import('@/views/MessagesChatOfferNew')
const MessagesChatEventNew = () => import('@/views/MessagesChatEventNew')

const NotificationsCenter = () => import('@/views/NotificationsCenter')

const User = () => import('@/views/User')

import ErrorView from '@/views/ErrorView'

import store from '@/store'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Landing',
		component: Landing,
		meta: { requiresGuest: true }
	},
	{
		path: '/*',
		component: Layout,
		children: [
			{
				path: '/auth/login',
				name: 'AuthLogin',
				component: AuthLogin,
				meta: { requiresGuest: true }
			},
			{
				path: '/auth/register',
				name: 'AuthRegister',
				component: AuthRegister,
				meta: { requiresGuest: true }
			},
			{
				path: '/auth/logout',
				name: 'AuthLogout',
				component: AuthLogout
			},
			{
				path: '/auth/verify',
				name: 'AuthVerify',
				component: AuthVerify
			},
			{
				path: '/auth/setup',
				name: 'AuthSetup',
				component: AuthSetup,
				meta: { requiresAuth: true }
			},
			{
				path: '/auth/resetPassword',
				name: 'AuthResetPassword',
				component: AuthResetPassword,
				meta: { requiresGuest: true }
			},
			{
				path: '/policies',
				name: 'Policies',
				component: Policies
			},
			{
				path: '/policies/cookies',
				name: 'PoliciesCookies',
				component: PoliciesCookies
			},
			{
				path: '/policies/privacy',
				name: 'PoliciesPrivacy',
				component: PoliciesPrivacy
			},
			{
				path: '/policies/terms',
				name: 'PoliciesTerms',
				component: PoliciesTerms
			},
			{
				path: '/settings',
				name: 'Settings',
				component: Settings,
				meta: { requiresAuth: true },
				redirect: { name: 'SettingsAccount' },
				children: [
					{ path: 'account', name: 'SettingsAccount', component: SettingsAccount, meta: { requiresAuth: true } },
					{ path: 'company', name: 'SettingsCompany', component: SettingsCompany, meta: { requiresAuth: true, requiresCompany: true } },
					{
						path: 'subscriptions',
						name: 'SettingsSubscriptions',
						component: SettingsSubscriptions,
						meta: { requiresAuth: true, requiresCompany: true }
					},
					{ path: 'notifications', name: 'SettingsNotifications', component: SettingsNotifications, meta: { requiresAuth: true } }
				]
			},
			{
				path: '/settings/invoice/verify',
				name: 'SettingsInvoiceVerify',
				component: SettingsInvoiceVerify
			},
			{
				path: '/company',
				name: 'Company',
				component: Company,
				meta: { requiresAuth: true, requiresCompany: true }
			},
			{
				path: '/company/invitation',
				name: 'CompanyInvitation',
				component: CompanyInvitation
			},
			{
				path: '/users/:id',
				name: 'User',
				component: User,
				meta: { requiresAuth: true, requiresCompany: true }
			},
			{
				path: '/offers',
				name: 'Offers',
				component: Offers,
				meta: { requiresAuth: true, requiresCompany: true }
			},
			{
				path: '/offers/create',
				name: 'OfferCreate',
				component: OfferCreate,
				meta: { requiresAuth: true, requiresCompany: true }
			},
			{
				path: '/offers/:id',
				name: 'Offer',
				component: Offer,
				meta: { requiresAuth: true, requiresCompany: true },
				redirect: { name: 'OfferAbout' },
				children: [
					{ path: 'about', name: 'OfferAbout', component: OfferAbout, meta: { requiresAuth: true, requiresCompany: true } },
					{ path: 'benefits', name: 'OfferBenefits', component: OfferBenefits, meta: { requiresAuth: true, requiresCompany: true } },
					{ path: 'needs', name: 'OfferNeeds', component: OfferNeeds, meta: { requiresAuth: true, requiresCompany: true } },
					{ path: 'activity', name: 'OfferActivity', component: OfferActivity, meta: { requiresAuth: true, requiresCompany: true } },
					{ path: 'applications', name: 'OfferApplications', component: OfferApplications, meta: { requiresAuth: true, requiresCompany: true } },
					{ path: 'talentPool', name: 'OfferTalentPool', component: OfferTalentPool, meta: { requiresAuth: true, requiresCompany: true } }
				]
			},
			{
				path: '/events',
				name: 'Events',
				component: Events,
				meta: { requiresAuth: true, requiresCompany: true }
			},
			{
				path: '/events/create',
				name: 'EventCreate',
				component: EventCreate,
				meta: { requiresAuth: true, requiresCompany: true }
			},
			{
				path: '/events/:id',
				name: 'Event',
				component: Event,
				meta: { requiresAuth: true, requiresCompany: true },
				redirect: { name: 'EventFeed' },
				children: [
					{ path: 'feed', name: 'EventFeed', component: EventFeed, meta: { requiresAuth: true, requiresCompany: true } },
					{ path: 'description', name: 'EventDescription', component: EventDescription, meta: { requiresAuth: true, requiresCompany: true } },
					{ path: 'activity', name: 'EventActivity', component: EventActivity, meta: { requiresAuth: true, requiresCompany: true } },
					{ path: 'attendances', name: 'EventAttendances', component: EventAttendances, meta: { requiresAuth: true, requiresCompany: true } }
				]
			},
			{
				path: '/subscriptions/:id',
				name: 'Subscription',
				component: Subscription,
				meta: { requiresAuth: true, requiresCompany: true }
			},
			{
				path: '/messages',
				name: 'Messages',
				component: Messages,
				meta: { requiresAuth: true, requiresCompany: true },
				children: [
					{ path: ':id', name: 'MessagesChat', component: MessagesChat, meta: { requiresAuth: true, requiresCompany: true } },
					{
						path: 'offer',
						name: 'MessagesChatOfferNew',
						component: MessagesChatOfferNew,
						meta: { requiresAuth: true, requiresCompany: true },
						props: true
					},
					{
						path: 'event',
						name: 'MessagesChatEventNew',
						component: MessagesChatEventNew,
						meta: { requiresAuth: true, requiresCompany: true },
						props: true
					}
				]
			},
			{
				path: '/contact',
				name: 'Contact',
				component: Contact
			},
			{
				path: '/faq',
				name: 'FAQ',
				component: FAQ
			},
			{
				path: '/pricing',
				name: 'Pricing',
				component: Pricing
			},
			{
				path: '/pricing/confirm',
				name: 'PricingConfirm',
				component: PricingConfirm,
				meta: { requiresAuth: true },
				props: true
			},
			{
				path: '/notifications',
				name: 'NotificationsCenter',
				component: NotificationsCenter,
				meta: { requiresAuth: true }
			},
			{
				path: '/team',
				name: 'Team',
				component: Team
			},
			{
				path: '/error/:type',
				name: 'Error',
				component: ErrorView,
				props: true
			},
			{
				path: '/*',
				redirect: { name: 'Error', params: { type: '404' } }
			}
		]
	}
]

const router = new VueRouter({
	linkActiveClass: 'active',
	linkExactActiveClass: 'active',
	mode: 'history',
	routes,
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition
		} else {
			return { x: 0, y: 0 }
		}
	}
})

router.beforeEach((to, from, next) => {
	document.title = to.name

	if (to.matched.some((record) => record.meta.requiresAuth)) {
		if (!store.getters['auth/authenticated']) {
			next({
				name: 'AuthLogin',
				query: { redirect: to.fullPath }
			})
		} else {
			next()
		}
	}

	if (to.matched.some((record) => record.meta.requiresCompany)) {
		if (!store.getters['auth/authenticated'] && !store.getters['user/setupInfo'].joinedCompany) {
			next({ name: 'AuthSetup' })
		} else {
			next()
		}
	}

	if (to.matched.some((record) => record.meta.requiresGuest)) {
		if (store.getters['auth/authenticated']) {
			next({ name: 'Company' })
		} else {
			next()
		}
	}

	next()
})

export default router
